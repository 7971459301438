import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";

const numberFormats: any = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  },
  "es-CO": {
    currency: {
      style: "currency",
      currency: "COP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  "es-PE": {
    currency: {
      style: "currency",
      currency: "PEN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  },
  "pt-BR": {
    currency: {
      style: "currency",
      currency: "BRL",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  },
  "es-CL": {
    currency: {
      style: "currency",
      currency: "CLP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  "es-MX": {
    currency: {
      style: "currency",
      currency: "MXN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  },
  "es-CR": {
    currency: {
      style: "currency",
      currency: "CRC",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  }
};

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "es" || "en" || "pt",
  fallbackLocale: "es" || "en",
  availableLocales: ["es", "en", "pt"],
  messages: messages,
  numberFormats: numberFormats,
});
