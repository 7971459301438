import http from "../http-common";

class MenuService {
  getMenuCache(supplier_id: number, service: string, subservice: string, trigger?: string, lang?: string) {
    const baseURL = import.meta.env.VITE_APP_API_CACHE;
    const headers = {
      'lang': lang
    };
    return http.get<any>(`${baseURL}/v1/menu/${supplier_id}/${service}${subservice ? `/${subservice}.json` : ".json"}?lang=${lang||'es'}`.trim(), { headers });
  }
  getMenuNonCache(supplier_id: number, service: string, subservice: string, trigger?: string, lang?: string) {
    const baseURL = import.meta.env.VITE_APP_API_NONCACHE;
    const headers = {
      'lang':lang
    };
    return http.get<any>(`${baseURL}/v1/menu/${supplier_id}/${service}${subservice ? `/${subservice}.json` : ".json"}?lang=${lang||'es'}`.trim(), { headers });
  }
  getProductDetail(product_supplier_id: string, lang?: string) {
    const baseURL = import.meta.env.VITE_APP_API_EXP;
    const headers = {
      'lang':lang
    };
    return http.get<any>(`${baseURL}/domain_v2/products/${product_supplier_id}.json`, { headers });
  }
  SendRateOnTable = (data:Object) => {
    const baseURL = import.meta.env.VITE_APP_API_EXP;
    return http.post<any>(`${baseURL}/menudigital/rates_services.json`,data);
  }
  getCssProperties = (supplier_id:number) => {
    const baseURL = import.meta.env.VITE_APP_API_NONCACHE;
    return http.get<any>(`${baseURL}/api/css_properties/${supplier_id}.json`);
  }
}

export default new MenuService();
