export function useDevice () {
  let targetDivApp: HTMLElement | null = null;
  const detectOsType = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    let osType = '';
    if (/android/i.test(userAgent)) osType = 'android';
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) osType = 'ios';
    else if (/Win/i.test(userAgent)) osType = 'windows';
    else if (/Linux/i.test(userAgent)) osType = 'linux';
    else osType = 'other';
    addClassTypeOs(osType);
    return osType;
  };
  const detectScreenSize = () => {
    const width = window.innerWidth;
    let type = '';
    if (width >= 1024) type = 'desktop';
    else if (width >= 768) type = 'tablet';
    else type = 'mobile';
    return type;
  };
  const detectOrientation = () => {
    let orientation = '';
    orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    return orientation;
  };
  const addClassTypeOs = (operatingSystem:string) => {
    targetDivApp = document.getElementById('app');
    if (targetDivApp) targetDivApp.classList.add(`os-${operatingSystem}`);
  };

  return {
    detectOsType, detectScreenSize, detectOrientation
  };
}