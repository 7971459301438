import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { DataTrack } from "@/models/dataTrack.interface";
import { CardClass, Product, ResponseMenu, Customer, Supplier, ContentPopup, Category } from "@/models/responseMenu.interface";
import TrackService from "@/services/track-custom";
import router from "@/router";
import { Profile } from '@/models/profile.interface';
import { ResponseTypeform } from '../models/responseTypeForm.interface';
import { CssPropertiesFlags } from "@/models/responseCssProperties.interface";

export const useMenuStore = defineStore("menuStore", () => {
  //state
  let count = ref(0);
  let menuData = ref<ResponseMenu>({} as ResponseMenu);
  let popup = ref<{ show: Boolean; timeSteps: number[] }>({
    show: false,
    timeSteps: [40000, 40000, 120000, 120000, 120000, 120000],
  });
  let allergenPopup = ref<{ show: Boolean; hasAllergens: Boolean }>({ show: false, hasAllergens: false });
  let classGridMenu = ref<CardClass>(CardClass.IMAGEDEFAULT);
  let seeListProducts = ref<boolean>(false);
  let headerClick = ref<number>(0);
  let navHome = ref<boolean>(false);
  let alertHeader = ref<boolean>(false);
  let trakingData = ref<DataTrack>({});
  let productClicked = ref<Product>({} as Product);
  let selectedCategory = ref<number>();
  let selectedSubCategory = ref<number>();
  let customerData = ref<Customer>({} as Customer);
  let skeletonLoad = ref<boolean>(true);
  let openModalExit = ref<boolean>(false);
  let profileData = ref<Profile>({} as Profile);
  let flatHomeAndStorieMode = ref<boolean>(false);
  let typeFormData = ref<ResponseTypeform[]>([] as ResponseTypeform[]);
  const currentSupplier = ref<Supplier>({} as Supplier);
  const customerSuppliers = ref<Supplier[]>([] as Supplier[]);
  const contentPopups = ref<ContentPopup[]>([] as ContentPopup[]);
  let flatRateNps = ref<boolean>(true);
  let flatSuccessRate = ref<boolean>(false);
  let flatSplashScreenCluvi = ref<boolean>(true);
  let flatButtonRecommendedCategoriePosition = ref<boolean>(false);
  let flagCssCustomObj = ref<CssPropertiesFlags>({
    flagViewGridProducts: true,
    flagAlertGridProducts: false,
    flagFloatingButtonSurvey: true,
    flagButtonMenuLat: true,
    flagAllergenPopup: true,
    flagCategoriesPager: true,
    flagLateralMenuButtonRecommended: true,
    flagConfigInCardProduct: false,
    modeMenu: 'stories',
    flagSliderCategoriesImage: true,
    flagViewButtonRightCategories: false
  });
  let currentCategory = ref<Category>();
  let mainCategories = ref<Category[]>([]);
  let subCategories = ref<Category[]>([]);
  let hasChanged = ref<boolean>(false);
  let countAlertAlegents = ref<number>(0); // Esta variable se debe eliminar cuando se haga el refactor para mantener una vista, se coloco para banners
  let TypeOS = ref<string>('other');
  let TypeDevice = ref<string>('desktop');
  let TypeOrientation = ref<string>('portrait');

  //computed
  const showFootNavCategories = computed<boolean>(() => {
    return flagCssCustomObj.value.modeMenu === 'banners';
  });
  const addAllCategoriesOnStoriesMode = computed<boolean>(() => {
    return menuData.value.customer?.new_menu1_params?.add_item_allcategories;
  });

  let defaultLanguageLocalStorage = ref<string>("");
  //localstorage
  if (localStorage.getItem("vuex")) {
    let storageData = null;
    storageData = JSON.parse(localStorage.getItem("vuex") as string);
    trakingData.value.customer_id = storageData?.customer.instance.id;
    trakingData.value.session_id = storageData?.profile.session.id;
    trakingData.value.profile_id = storageData?.profile.profile.id;
    trakingData.value.acquisition_id = storageData?.shared.acquisitionId;
  } else {
    if (JSON.parse(localStorage.getItem("trakingData") as string)) trakingData.value = JSON.parse(localStorage.getItem("trakingData") as string);
  };

  if (JSON.parse(localStorage.getItem("lang") as string)) defaultLanguageLocalStorage.value = JSON.parse(localStorage.getItem("lang") as string); 

  //actions
  const viewsGridProducts = (classGrid: CardClass) => {
    classGridMenu.value = classGrid;
    alertHeader.value = false;
    headerClick.value++;
  };
  const goToHomeMenu = () => {
    TrackService.TrackEventCluvi({}, "footer click home");
    router.push({ name: "Menu" });
    document.title = "";
    navHome.value = true;
  };
  const navOtherServices = (dir: string) => {
    const urlHost = window.location.origin;
    if (dir === "book") window.open(urlHost + "/branch-to-book", "_self");
    else if (dir === "delivery") window.open(urlHost + "/location", "_self");
    else if (dir === "takeAway") window.open(urlHost + "/branch-to-take-away", "_self");
  };
  const setMenu = (menu: ResponseMenu) => {
    if (menuData) {
      menuData.value = menu;
      menuData.value.customer?.popup_frecuency?.length && (popup.value.timeSteps = menuData.value.customer?.popup_frecuency);
    };
  };
  const trakingNewMenu = async () => {
    try {
      if (!trakingData.value.customer_id) {
        trakingData.value.customer_id = menuData.value.customer?.id;
      };
      if (!trakingData.value.session_id && trakingData.value.customer_id) {
        const session = (await TrackService.InitSession()).data;
        trakingData.value.session_id = session.id;
      };
      if (!trakingData.value.acquisition_id && trakingData.value.customer_id && trakingData.value.session_id) {
        const acquisitionId = (
          await TrackService.StoreAcquisitionData(window.location.search, trakingData.value.session_id, trakingData.value.customer_id)
        ).data;
        trakingData.value.acquisition_id = acquisitionId.id;
      };
    } catch (error) {
      console.error(error);
      if (!trakingData.value.session_id && trakingData.value.customer_id) {
        const session = (await TrackService.InitSession()).data;
        trakingData.value.session_id = session.id;
      };
    }
  };
  //watch
  watch(
    trakingData,
    (value) => {
      localStorage.setItem("trakingData", JSON.stringify(value));
    },
    { deep: true }
  );
  watch(defaultLanguageLocalStorage, (val) => {
    if (JSON.parse(localStorage.getItem("lang") as string) !== null && val) hasChanged.value = true;
    localStorage.setItem("lang", JSON.stringify(val));
  });

  return {
    count,
    menuData,
    popup,
    allergenPopup,
    navHome,
    classGridMenu,
    seeListProducts,
    alertHeader,
    trakingData,
    productClicked,
    selectedCategory,
    selectedSubCategory,
    setMenu,
    goToHomeMenu,
    viewsGridProducts,
    trakingNewMenu,
    customerData,
    currentSupplier,
    customerSuppliers,
    contentPopups,
    navOtherServices,
    skeletonLoad,
    defaultLanguageLocalStorage,
    headerClick,
    showFootNavCategories,
    openModalExit,
    addAllCategoriesOnStoriesMode,
    profileData,
    flatHomeAndStorieMode,
    typeFormData,
    flatRateNps,
    flatSuccessRate,
    flatSplashScreenCluvi,
    flatButtonRecommendedCategoriePosition,
    flagCssCustomObj,
    currentCategory,
    mainCategories,
    subCategories,
    hasChanged,
    countAlertAlegents,
    TypeOS,
    TypeDevice,
    TypeOrientation
  };
});
