import type {AxiosInstance} from "axios";

export const interceptorsSetup = (http: AxiosInstance) => {
    http.interceptors.request.use(
        (request: any) => {
          return request;
        },
        (err) => {
            return Promise.reject(err);
        }
    );
}
