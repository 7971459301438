import http from "../http-common";

class SupplierSurveysService {
  getSupplierSurveys (supplier_id: number) {
    const baseURL = import.meta.env.VITE_APP_API_NONCACHE;
    return http.get<any>(`${baseURL}/surveys/admin/supplier_surveys/supplier_surveys.json?supplier_id=${supplier_id}`);

  }
  getSupplierSurveysHideRate (supplier_id: number) {
    const baseURL = import.meta.env.VITE_APP_API_NONCACHE;
    return http.get<any>(`${baseURL}/surveys/admin/supplier_surveys/detail_supplier_surveys.json?supplier_id=${supplier_id}&service=on_table`);
  }
}

export default new SupplierSurveysService();
