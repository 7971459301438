export interface ResponseMenu {
  upselling_products: UpsellingProducts[];
  content_home: ContentHome[];
  sponsor_banners: SponsorBanner[];
  content_popups: ContentPopup[];
  menu: Menu;
  customer: Customer;
  cluvi_add_ons: any[];
  current_supplier: Supplier;
  customer_suppliers: Supplier[];
}

export interface ContentHome {
  id: string;
  title: string;
  image: Image | null;
  param_video: string | null;
  layout_type: string;
  target: string;
  product_ids: string[];
  url?: string;
  products?: Product[];
}

export interface Image {
  blog?: string;
  thumb?: string;
  w_576?: string;
  w_768?: string;
  w_992?: string;
  w_1200?: string;
  mini?: string;
  image?: string;
}

export interface URL {}

export interface ContentPopup {
  id: string;
  image: Image;
  master_popup_id: string;
  trigger: string;
  customer_id: string;
  active: boolean;
  title: string;
  product_ids: any[];
}

export interface Menu {
  categories: Category[];
  products: Product[];
  configs: []
}

export interface Category {
  id: number;
  image: Image | null;
  image_banner?: Image | null;
  image_icon?: Image | null;
  label: string;
  order: number;
  main_category_id: number | null;
  quantity_previous_products: number;
  type: Type;
  subcategories: Category[];
  product_ids: string[];
  products?: Product[];
  card_type?: string;
  arrowUp?: Boolean;
}

export enum Type {
  MainCategory = "MainCategory",
  SupplierCategory = "SupplierCategory",
}

export interface Product {
  id: string;
  label: string;
  description: null | string;
  important: boolean;
  image: Image | null;
  is_alcoholic: boolean | null;
  is_new: boolean | null;
  price: string;
  price_full: string;
  product_supplier_id: string;
  favorite: boolean;
  video: string | null;
  card_type?: string | null;
  products_super_tag: any[];
  allergy_tags: AllergyTag[];
  configs?: [],
  promo_porcent?: string,
  configsInfo?: undefined[],
  out_of_stock?: string,
  type_card?: string,
  redirect_to?: string,
  attachs: undefined[]
}

export interface SponsorBanner {
  id: string;
  title: string;
  image: Image;
  master_product_id: string;
  url: null | string;
}

export interface UpsellingProducts {
  label_popup: string;
  label_popup_menu: string;
  label_precheckout: string;
  products: string[];
  listProducts: Product[];
}

export enum CardClass {
  IMAGEXL = "card-colWrap--imageXL",
  IMAGEMD = "card-colWrap--imageMD",
  IMAGEDEFAULT = "card-colWrap",
}

export interface Customer {
  menu_cached: boolean;
  currency: string;
  currency_locale: string;
  country_code: string;
  langs: string[];
  language: string;
  labels_buttons: LabelsButtons;
  header: Header;
  rate_digitalmenu: boolean;
  rate_nps: boolean;
  socialmedia: Socialmedia;
  pixel_google_analytics: string;
  pixel_facebook_analytics: string;
  new_menu1_params?: any;
  name?: string;
  id?: string;
  popup_frecuency?: any;
}

export interface Supplier {
  id: number;
  label: string;
  service_table: boolean;
  on_table_class: string;
  ciudad_obj: any;
}

export interface Header {
  logo: string;
  label: string;
}

export interface LabelsButtons {
  book: string;
  delivery: string;
  on_table: string;
  schedule: string;
  take_away: string;
  nuestras_tiendas: string;
}

export interface Socialmedia {
  tiktok: string;
  spotify: string;
  twitter: string;
  youtube: string;
  facebook: string;
  whatsapp: string;
  instagram: string;
  tripadvisor: string;
}

export interface AllergyTag {
  name: string;
  desccription: string;
  css_class: string;
}
