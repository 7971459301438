import { createRouter, createWebHistory } from "vue-router";

const loadComponent = (name: string) => {
  return import(`@/views/${name}.vue`);
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    //if(savedPosition && from.query?.product) return savedPosition;
    //else return { top: 0, behavior: 'smooth' };
  },
  routes: [
    {
      path: "/:pathMatch(.*)*",
      name: "notFound",
      component: () => loadComponent("NotFoundView"),
    },
    {
      path: "/:supplier_id/:service/:subservice",
      strict: true,
      children: [
        {
          path: "",
          name: "Menu",
          component: () => loadComponent("HomeView"),
        },
        {
          path: "allcategories",
          name: "AllCategory",
          component: () => loadComponent("HomeView"),
        },
        {
          path: "recommendedcategories",
          name: "RecommendedCategory",
          component: () => loadComponent("HomeView"),
        },
        {
          path: "categories",
          strict: true,
          children: [
            {
              path: "",
              strict: true,
              name: "Categories",
              component: () => loadComponent("HomeView"),
            },
            {
              path: ":category_id",
              name: "Category",
              component: () => loadComponent("HomeView"),
              children: [
                {
                  path: "subcategories/:subcategory_id",
                  name: "SubCategory",
                  strict: true,
                  component: () => loadComponent("HomeView"),
                }
              ],
            },
          ],
        },
        {
          path: "main-categories",
          strict: true,
          children: [
            {
              path: "",
              name: "BannerCategories",
              component: () => loadComponent("Categories"),
            },
            {
              path: ":category_id",
              name: "MainCategory",
              component: () => loadComponent("Categories"),
              children: [
                {
                  path: "subcategories/:subcategory_id",
                  name: "BannerSubCategory",
                  strict: true,
                  component: () => loadComponent("HomeView"),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export default router;
