import { useMenuStore } from "@/store";
import { storeToRefs } from "pinia";
import { useI18n } from 'vue-i18n';
import { ref, watch, onMounted } from "vue";

export function useLanguage() {
  interface ModalLangModel {
    label: string,
    value: string,
    icon: string,
    image: string,
  };
  type LanguageInfo = {
    label: string;
    icon: string;
    image: string;
  };

  const LANGUAGES_INFO: {
    [key: string]: LanguageInfo;
    EN: LanguageInfo;
    PT: LanguageInfo;
    ES: LanguageInfo;
  } = {
    EN: { label: 'ENGLISH', icon: 'usa', image: 'en' },
    PT: { label: 'PORTUGUESE', icon: 'bra', image: 'pt' },
    ES: { label: 'SPANISH', icon: 'esp', image: 'es' },
  };

  const menuStore = useMenuStore();
  const { customerData, defaultLanguageLocalStorage } = storeToRefs(menuStore);
  const { t } = useI18n();
  const { n } = useI18n();
  const languages = ref<ModalLangModel[]>([]);
  const changeLang = ref<string>("");

  const getLabels = (tag: string) => {
    if (tag) {
      const tagCountry = `${tag}.${customerData.value.country_code}`;
      const tagDefault = `${tag}.DEFAULT`;
      const labelCountry: any = t(tagCountry);
      const labelsDefault: any = t(tagDefault);
      if (labelCountry === tagCountry) return labelsDefault;
      else return labelCountry;
    };
  };
  const priceCurrencyLocale = (data: any) => {
    return n(Number(data), "currency", customerData.value.currency_locale);
  };
  const languagesInstance = () => {
    const allLanguages = [...(customerData.value.langs || []), customerData.value.language].filter(value => Object.keys(LANGUAGES_INFO).includes(value));
    languages.value = [...new Set(allLanguages)].map(item => {
      const { label, icon, image } = LANGUAGES_INFO[item];
      return {
        label: getLabels(`LANGUAGE.RADIOLABEL${label}`),
        value: item,
        icon: `modal-flag modal-flag__${icon}`,
        image: `menuLat-flag-image__${image}`
      };
    });
    if (allLanguages.includes(defaultLanguageLocalStorage.value)) {
      changeLang.value = defaultLanguageLocalStorage.value;
    }
  };
  const getLabelsWithVariables = (tag:string,keyVar:string,variable:string) => {
    if (tag) {
      const tagDefault = `${tag}.DEFAULT`;
      const labelsDefault: string = t(tagDefault,{ keyVar: variable });
      return labelsDefault;
    };
  };

  watch(customerData, languagesInstance);
  onMounted(languagesInstance);

  return {
    getLabels, priceCurrencyLocale, languages,
    changeLang, languagesInstance, customerData,
    getLabelsWithVariables
  };
}
