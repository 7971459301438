import { Category, Product, Type, Image } from "@/models/responseMenu.interface";
import TrackService from "@/services/track-custom";
import { useRouter, useRoute, RouteRecordName } from "vue-router";
import { useMenuStore } from "@/store";
import { storeToRefs } from "pinia";
import { useLanguage } from "@/helpers/language-composables";

export function useClickEvent() {
  const router = useRouter();
  const route = useRoute();
  const menuStore = useMenuStore();
  const { menuData, trakingData,showFootNavCategories,addAllCategoriesOnStoriesMode } = storeToRefs(menuStore);
  const { getLabels } = useLanguage();

  function clickBanner(item: any, data_track: any) {
    if (data_track.content_popup !== undefined) TrackService.TrackEventCluvi(data_track, "click popup");
    if (data_track.content_home !== undefined) TrackService.TrackEventCluvi(data_track, "click banner");
    if (item.url === undefined || item.url === null || item.url.tipe === null || item.url.tipe === undefined) return;
    if (item.url.tipe === "producto") {
      const product = findProduct(item.url.param);
      clickProduct(product);
    };
    if (item.url.tipe === "categoria") {
      const category = findCategory(menuData.value.menu.categories, item.url.param);
      clickCategory(category as Category);
    };
    if (item.url.tipe === "externo") window.open(item.url.param, item.target);
  };
  function findCategory(categories: Category[], id: number): Category {
    let category2Show!: Category;
    categories.forEach((item) => {
      if (item.id === id) {
        category2Show = item;
        return;
      };
      if (item.subcategories.length) {
        let subcategory = findCategory(item.subcategories, id);
        if (subcategory) {
          category2Show = subcategory;
          return;
        }
      };
    });
    return category2Show;
  };
  function clickCategory(category: Category) {
    let newRoute: any = {};
    if (category.id === 0) {
      if (category.main_category_id !== 1) {
        newRoute = {
          name: showFootNavCategories.value ? "BannerSubCategory" : "SubCategory",
          params: {
            category_id: category.main_category_id,
            subcategory_id: category.id,
          },
        };
      } else {
        newRoute = {
          name:  showFootNavCategories.value ? "MainCategory" : addAllCategoriesOnStoriesMode.value ? "AllCategory" : "Category",
          params: {
            service: trakingData.value.service,
            subservice: trakingData.value.subservice,
            supplier_id: trakingData.value.supplier_id
          },
        };
      }
      !addAllCategoriesOnStoriesMode && (newRoute.params.category_id = route.params?.category_id);
    } else if (category.id === 1 && category.main_category_id === 1) {
      newRoute = {
        name: "RecommendedCategory",
        params: {
          service: trakingData.value.service,
          subservice: trakingData.value.subservice,
          supplier_id: trakingData.value.supplier_id
        }
      };
    } else {
      if (category.main_category_id !== null) {
        newRoute = {
          name: showFootNavCategories.value ? "BannerSubCategory" : "SubCategory",
          params: {
            category_id: category.main_category_id,
            subcategory_id: category.id,
          },
        };
      } else {
        newRoute = {
          name: showFootNavCategories.value ? "MainCategory" : "Category",
          params: {
            category_id: category.id,
          },
        };
      };
    };
    router.push(newRoute);
    document.title = category.label;
  };
  function findProduct(id: string): Product {
    return menuData.value.menu.products.find((prod) => prod.product_supplier_id === id) as Product;
  };
  function clickProduct(product: Product) {
    document.title = product.label;
    const newQuery = { product: product.product_supplier_id };
    router.push({ name: route.name as RouteRecordName, params: route.params, query: { ...route.query, ...newQuery } });
  };
  function deleteCategorieMain(categories: Category[]): Category[] {
    return categories.filter((category) => {
      return (
        category?.product_ids?.length ||
        (category.subcategories.length && category?.subcategories.some((subcategoy) => subcategoy.product_ids.length))
      );
    });
  };
  const addCategoriesToMain = (categories: Category[]) => {
    const isMainCategorie = categories && categories.length > 0 && categories[0].id !== 0 && categories[0].type === 'MainCategory' ? true : false;
    const hasStoriesMode = !showFootNavCategories.value ? true : false;
    const hasCategorieAll = addAllCategoriesOnStoriesMode.value;
    const hasContentHome = menuData.value.content_home.length > 0 ? true : false;
    if (isMainCategorie && hasStoriesMode && hasCategorieAll) addCategorieAll(categories);
    if (hasContentHome) addCategorieRecommended(categories,hasCategorieAll);
    return categories;
  };
  const addCategorieAll = (categories: Category[]) => {
    let icon_image_all:Image;
    icon_image_all = {
      blog: "https://images.cluvi.com/lbNliGACha/blog_lbNliGACha_default_all.png",
      image: "https://images.cluvi.com/lbNliGACha/lbNliGACha_default_all.png",
      mini: "https://images.cluvi.com/lbNliGACha/mini_lbNliGACha_default_all.png",
      thumb: "https://images.cluvi.com/lbNliGACha/thumb_lbNliGACha_default_all.png",
      w_576: "https://images.cluvi.com/lbNliGACha/w_576_lbNliGACha_default_all.png",
      w_768: "https://images.cluvi.com/lbNliGACha/w_768_lbNliGACha_default_all.png",
      w_992: "https://images.cluvi.com/lbNliGACha/w_992_lbNliGACha_default_all.png",
      w_1200: "https://images.cluvi.com/lbNliGACha/w_1200_lbNliGACha_default_all.png"
    }
    const dataCategorieAll: Category = createCategorie(0,icon_image_all,getLabels("LABELALL"),0,'SupplierCategory' as Type);
    addCategorie(categories, dataCategorieAll, 0);
  };
  const addCategorieRecommended = (categories: Category[],hasCategorieAll:Boolean) => {
    let icon_image_recommended:Image;
    icon_image_recommended = {
      blog: "https://images.cluvi.com/LjkYdIG8tT/blog_LjkYdIG8tT_dafault_home.png",
      image: "https://images.cluvi.com/LjkYdIG8tT/LjkYdIG8tT_dafault_home.png",
      mini: "https://images.cluvi.com/LjkYdIG8tT/mini_LjkYdIG8tT_dafault_home.png",
      thumb: "https://images.cluvi.com/LjkYdIG8tT/thumb_LjkYdIG8tT_dafault_home.png",
      w_576: "https://images.cluvi.com/LjkYdIG8tT/w_576_LjkYdIG8tT_dafault_home.png",
      w_768: "https://images.cluvi.com/LjkYdIG8tT/w_768_LjkYdIG8tT_dafault_home.png",
      w_992: "https://images.cluvi.com/LjkYdIG8tT/w_992_LjkYdIG8tT_dafault_home.png",
      w_1200: "https://images.cluvi.com/LjkYdIG8tT/w_1200_LjkYdIG8tT_dafault_home.png"
    }
    const dataCategorieRecommended: Category  = createCategorie(1,icon_image_recommended,getLabels("LABELRECOMENDADOS"),1,'SupplierCategory' as Type);
    if (hasCategorieAll) addCategorie(categories, dataCategorieRecommended, 1);
    else addCategorie(categories, dataCategorieRecommended, 0);
  };
  const addCategorie = (categories: Category[],newCategory: Category,position: number): void => {
    categories.splice(position, 0, newCategory);
  };
  const createCategorie = (id: number,image_icon: Image,label: string,order: number,type: Type) => ({
    id,
    image_icon: image_icon,
    image_banner: null,
    image: null,
    label,
    order,
    main_category_id: 1,
    quantity_previous_products: 0,
    type,
    subcategories: [],
    product_ids: [],
    products: [],
  });

  return { clickBanner,clickProduct,findCategory,findProduct,clickCategory,deleteCategorieMain,addCategoriesToMain };
}