import { useMenuStore } from '@/store';
import { httpClientIntegration } from '@sentry/integrations';
import './style.css';
import * as Sentry from '@sentry/vue';
import { createPinia, storeToRefs } from 'pinia';
import PrimeVue from 'primevue/config';
import { createApp } from 'vue';
import VueLazyLoad from 'vue3-lazyload';
import App from './App.vue';
import { gtm } from './gtm';
import { i18n } from './i18n';
import { components } from './primevue';
import './assets/styles/main.scss';
import router from './router';
// @ts-ignore
import storeReset from './store/plugins/reset';
import 'animate.css';

const store = createPinia();
store.use( storeReset );

export const app = createApp( App ).use( store );

Sentry.init( {
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_ENVIRONMENT_STAGE,
	integrations: [
		Sentry.browserTracingIntegration( { router } ),
		httpClientIntegration()
	],
	transport: Sentry.makeBrowserOfflineTransport( Sentry.makeFetchTransport ),
	tracesSampleRate: 0,
	allowUrls: [
		/^https:\/\/.+\.menucluvi\.com[^a-z0-9]?.*$/,
		/^https:\/\/.+\.vue-testing\.cluvi-dev\.link[^a-z0-9]?.*$/,
		/^https:\/\/.+\.vue-pre-produccion\.cluvi-dev\.link[^a-z0-9]?.*$/
	],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 0
} );

app.use( router );
app.use( i18n );
app.use( PrimeVue );
app.use( VueLazyLoad, { loading: '' } );
app.use( gtm );

Object.entries( components ).forEach( ( [ name, component ] ) => app.component( name, component ) );

router.isReady().then( () => {
	app.mount( '#app' );
} );
//add this code in your app that you've embedded in Appsmith using the Iframe widget
const messageHandler = (event: any) => {
	if (event.data.variable) {
		if (event.data.variable === 'modeMenu') {
			setChangeMenuMode(event.data);
		} else if (event.data.variable === 'logo') {
			onChageLogo(event)
		} else {
			setCssCustom(event.data);
		}
	}
};

//add the event listener to read the incoming message
window.addEventListener( 'message', messageHandler );

function onChageLogo (event: any)  {
	const storeLocal = useMenuStore();
	storeLocal.customerData.header.logo = event.data.value;
}

function setChangeMenuMode( event: any ) {
	const storeLocal = useMenuStore();
	const { flagCssCustomObj } = storeToRefs( storeLocal );
	flagCssCustomObj.value.modeMenu = event.value;
	const params = router.currentRoute.value.params;
	const basicRoute = `${ params.supplier_id }/${ params.service }/${ params.subservice }`;
	if ( event.value === 'banners' ) {
		router.push( { path: `/${ basicRoute }/main-categories` } );
	} else {
		router.push( { path: `/${ basicRoute }` } );
	}
}

function setCssCustom( data: {
	variable: string,
	value: string
} ) {
	const r = document.querySelector( ':root' ) as any;
	r.style.setProperty( data.variable, data.value, 'important' );
}


