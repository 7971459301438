import axios from "axios";
import type {AxiosInstance} from "axios";
import {interceptorsSetup} from "./helpers/interceptor";

const apiClient: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_APP_API,
    headers: {
      "Content-type": "application/json"
    }
});

interceptorsSetup(apiClient);

export default apiClient;
