import http from "../http-common";
import { useMenuStore } from "../store/index";
import router from "../router";
import { app } from "@/main";
import VueGtag from "vue-gtag";
import { storeToRefs } from 'pinia';

class TrackService {
  TrackEventCluvi = (data: any, event: string,  view_vue: string='') => {
    const baseURL = import.meta.env.VITE_APP_API_CLUVIMKT;
    const store = useMenuStore();
    const { trakingData,defaultLanguageLocalStorage } = storeToRefs(store);
    let time = !trakingData.value.acquisition_id && !trakingData.value.session_id ? 3000 : 0;
    if(view_vue === '') view_vue = (router.currentRoute.value.name as string);
    if(view_vue === 'RecommendedCategory') view_vue = 'OnTableHome';
    if(view_vue === 'Category') view_vue = 'SubCategories';
    
    setTimeout(() => {
      const data_track: any = {
        event: event,
        view_vue: view_vue,
        front_lang: defaultLanguageLocalStorage.value.toLocaleLowerCase() as string || 'es',
        service: trakingData.value.service,
        customer_id: trakingData.value.customer_id,
        supplier_id: trakingData.value.supplier_id,
        acquisition_id: trakingData.value.acquisition_id,
        session_id: trakingData.value.session_id
      };
      if (data.product) {
        data_track.product_supplier_id = data.product.product_supplier_id;
        (data_track.product_id = data.product.id), (data_track.type_card = data.product.type_card_track);
        data_track.is_alcoholic = data.product.is_alcoholic;
        data_track.is_new = data.product.is_new;
        data_track.has_discount = data.product.promo;
        data_track.has_image = data.product.image !== null;
        data_track.image_format = this.getImageFormat(data.product.image)
        data_track.has_price = data.product.price > 0;
        data_track.is_important = data.product.important !== null && data.product.important;
        if (data.product.description !== null && data.product.description) data_track.description_number_letters = data.product.description.length;
        data_track.position_relative = data.product.position_relative;
        data_track.position_absolute = data.product.position_absolute;
        data_track.is_promo = data.product.is_promo;
        if(data.typeColumn) data_track.type_card = this.transformTypeCard(data.typeColumn);
      };
      if (data.content_popup){
        data_track.content_popup_id = data.content_popup.id;
        data_track.image_format = this.getImageFormat(data.content_popup.image);
      } 
      if (data.content_home) {
        data_track.content_home_id = data.content_home.id;
        data_track.image_format = this.getImageFormat(data.content_home.image);
      }
      if (data.upselling) {
        const product_supplier_ids = data.upselling.listProducts.map( (product:any) => product.product_supplier_id);
        data_track.product_supplier_ids = product_supplier_ids;        
      }
      if (data.purchase) data_track.purchase_id = data.purchase.id;
      if (data.purchase_id) data_track.purchase_id = data.purchase_id;
      if (data.products_cross) data_track.products_cross_id = data.products_cross.id;
      if (data.maincategory_id) data_track.maincategory_id = data.maincategory_id;
      if (data.subcategory_id) data_track.subcategory_id = data.subcategory_id;
      data_track.menu_version = "mf-menu";
      return http.post(`${baseURL}/api/trackings.json`, data_track);
    }, time);
  };
  transformTypeCard = (typeColumn: string) => {
    switch (typeColumn) {
      case "card-colWrap":
        return "list";
      case "card-colWrap--imageMD":
        return "small";
      case "card-colWrap--imageXL":
        return "large";
      default:
        return "";
    }
  };
  InitSession = () => {
    const store = useMenuStore();
    const baseURL = import.meta.env.VITE_APP_API_CLUVIMKT;
    const data = {
      customer_id: store.trakingData.customer_id,
      so: window.navigator.platform,
      browser: window.navigator.vendor,
      pixel_width: window.innerWidth,
      pixel_height: window.innerHeight,
    };
    return http.post(`${baseURL}/api/sessions.json`, data);
  };
  CreateProfileAnonymous = (domain: string, name: string) => {
    const baseURL = import.meta.env.VITE_APP_API;
    return http.post(`${baseURL}/profiles/anonymous.json`, {
      profile: { domain: domain, name: name },
    });
  };
  StoreAcquisitionData = (parameters: string, session_id: string, customer_id: string) => {
    let data: AcquisitionData = {};
    const urlParams = new URLSearchParams(parameters);
    data.campaign_id = urlParams.get("campaign_id") as string;
    data.utm_medium = urlParams.get("utm_medium") as string;
    data.utm_campaign = urlParams.get("utm_campaign") as string;
    data.utm_term = urlParams.get("utm_term") as string;
    data.utm_content = urlParams.get("utm_content") as string;
    data.gclid = urlParams.get("gclid") as string;
    data.fbclid = urlParams.get("fbclid") as string;
    data.session_id = session_id;
    data.customer_id = customer_id;
    data.userAgent = navigator.userAgent;
    data.referer = document.referrer;
    return this.Traking(data);
  };
  Traking = (data: AcquisitionData) => {
    const baseURL = import.meta.env.VITE_APP_API_CLUVIMKT;
    return http.post(`${baseURL}/api/acquisitions.json`, data);
  };
  load = () => {
    const store = useMenuStore();
    const { customerData } = store;
    if (customerData.pixel_google_analytics) {
      app.use(
        VueGtag,
        {
          config: {
            id: customerData.pixel_google_analytics as string,
          },
        },
        router
      );
    }
  };
  getImageFormat(image:any){
    try {
        const url = new URL(image.thumb);
        // Limpiar los parámetros de búsqueda
        url.search = '';
        return url.toString().split('.').pop();
    } catch (error) {
        return 'error'
    }
  };
}

interface AcquisitionData {
  campaign_id?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  gclid?: string;
  fbclid?: string;
  session_id?: string;
  customer_id?: string;
  userAgent?: string;
  referer?: string;
}

export default new TrackService();
